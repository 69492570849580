import { scrollTo } from '../helper/scroll-to';

/**
 * Scrolls to an element
 *
 * @param event
 */
const scroll = event => {
    event.preventDefault();
    const destination = event.target.getAttribute('href');
    const $destinationTarget = document.querySelector(destination);

    if ($destinationTarget) {
        const elementTop =
            $destinationTarget.getBoundingClientRect().top +
            document.documentElement.scrollTop;

        scrollTo(elementTop);
    }
};

/**
 * Initialize the content scroller
 */
export const init = () => {
    const $elements = document.querySelectorAll('[data-scroll]');

    Array.prototype.forEach.call($elements, $element =>
        $element.addEventListener('click', event => scroll(event))
    );
};
