import htmlToElement from 'html-to-element';
import { loadingSpinner } from '../ui/loading-spinner';
import createStore from '@shopmacher/ministore';
import { scrollTo } from '../helper/scroll-to';

const store = createStore();

const $reactRoot = document.querySelector('#react-root');
const $positionResultWrapper = document.querySelector('#position-result');

const mailConfig = {
    page: location.pathname,
    mailTo: '',
    slug: '',
    newsletter: '',
    contact: ''
};

const $resultWrapper = htmlToElement(`<div class="result-wrapper"></div>`);
const $wrapper = htmlToElement(`<div class="result-request"></div>`);
const $error = htmlToElement(`<div class="error cloaked"></div>`);
const $ctaText = htmlToElement(`<div class="cta-text"></div>`);
const $mailInput = htmlToElement(
    `<input type="text" name="email" placeholder="E-Mail Adresse" />`
);
const $nameInput = htmlToElement(
    `<input type="text" name="name" placeholder="Ihr Name" />`
);
const $button = htmlToElement(`<a class="btn btn--alternate">Abschicken</a>`);

let state = {
    containter: {},
    config: {
        ctaText: '',
        resultText: '',
        finishText: ''
    }
};

/**
 * Toggles the cloaked elements
 */
const toggleCloaked = () => {
    $mailInput.classList.toggle('cloaked');
    $nameInput.classList.toggle('cloaked');
    $button.classList.toggle('cloaked');
    loadingSpinner.classList.toggle('cloaked');
};

/**
 * Shows the returning error
 *
 * @param error
 * @param state
 * @param toggle
 */
const handleError = (error = '', state = true, toggle = true) => {
    if (state) {
        const message = typeof error === 'object' ? error.message : error;

        $error.innerHTML = `Es ist ein Fehler aufgetreten:<br>${message}`;
        $error.classList.remove('cloaked');

        if (toggle) {
            toggleCloaked();
        }
    } else {
        $error.innerHTML = '';
        $error.classList.add('cloaked');
    }
};

/**
 * Handles the click on the submit button
 */
const handleSubmit = () => {
    toggleCloaked();
    handleError('', false);

    mailConfig.mailTo = $mailInput.value || '';
    mailConfig.name = $nameInput.value || '';

    const post = {
        method: 'POST',
        body: JSON.stringify({
            mailConfig
        })
    };

    fetch(`/gb/mailing`, post)
        .then(response => {
            if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .then(response => finishAction(response))
        .catch(error => error.json().then(err => handleError(err, true, true)));
};

/**
 * Finishes the action
 *
 * @param response
 */
const finishAction = response => {
    if (response.status === 'success') {
        const { config } = state;

        $error.innerHTML = config.finishText;

        $mailInput.classList.add('cloaked');
        $button.classList.add('cloaked');
        loadingSpinner.classList.add('cloaked');
        $error.classList.remove('cloaked');
    }
};

/**
 * Build the result request forms
 */
const buildForm = () => {
    const { config } = state;

    $button.addEventListener('click', handleSubmit);

    $ctaText.innerHTML = config.ctaText;

    $wrapper.appendChild($nameInput);
    $wrapper.appendChild($mailInput);
    $wrapper.appendChild($button);
    $resultWrapper.appendChild($ctaText);
    $resultWrapper.appendChild($error);
    $resultWrapper.appendChild($wrapper);

    $positionResultWrapper.appendChild($resultWrapper);
    $positionResultWrapper.appendChild(loadingSpinner);
};

/**
 * Returns the active container index
 *
 * @param container
 */
const getCurrentContainerIndex = container => {
    let containerIndex = null;

    for (let i = 0, l = container.length; i < l; i++) {
        let found = container[i].questions.find(
            element => element.value === null
        );

        if (found) {
            containerIndex = i;
            break;
        }
    }

    return containerIndex;
};

/**
 * Handles the completed event
 *
 * @param event

 */
const handleCompletedEvent = event => {
    mailConfig.mailTo = $mailInput.value || '';
    mailConfig.name = $nameInput.value || '';

    store.register('complete', {
        priority: 2,
        handler: () =>
            fetch(`/gb/pages`, {
                method: 'POST',
                body: JSON.stringify({
                    container: event.detail.data,
                    mailConfig
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response.json();
                })
                .then(response => {
                    mailConfig.slug = response.data.slug;
                })
                .catch(error =>
                    error.json().then(err => handleError(err, true, false))
                )
    });

    // @TODO: Activate to notify for new reports

    // store.register('complete', {
    //     priority: 1,
    //     handler: () =>
    //         fetch(`/gb/notify`, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 mailConfig
    //             })
    //         })
    //             .then(response => {
    //                 if (!response.ok) {
    //                     throw response;
    //                 }
    //                 return response.json();
    //             })
    //             .then(response => console.log(response))
    //             .catch(error =>
    //                 error.json().then(err => console.error(err))
    //             )
    // });

    store.dispatch({ type: 'complete' });

    buildForm();

    const $destination = document.querySelector('#result');

    if ($destination) {
        const elementTop =
            $destination.getBoundingClientRect().top +
            document.documentElement.scrollTop;

        scrollTo(elementTop);
    }
};

/**
 * Initialize the position check completed event
 */
export const init = () => {
    if ($reactRoot && $positionResultWrapper) {
        $reactRoot.addEventListener('completed', event =>
            handleCompletedEvent(event)
        );

        // $reactRoot.addEventListener('updateContainer', event =>
        //     handleUpdateEvent(event)
        // );

        if ($reactRoot.getAttribute('data-state')) {
            try {
                state = JSON.parse($reactRoot.getAttribute('data-state'));
            } catch (e) {
                console.error(e);
            }
        }
    }
};
